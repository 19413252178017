import Script from 'next/script';
import { SiteDefinition } from '~/contexts/SiteContext';
import { CaringDomains } from '~/types/Domains';
import { Page } from '~/types/Magnolia';

const TalkFurther = ({
  domain,
  pageType,
  providerId,
}: {
  domain: SiteDefinition['path'];
  pageType: Page['pageType'];
  providerId?: number;
}): React.ReactElement => {
  // if (!process.env.NEXT_PUBLIC_TALKFURTHER_ENABLED) {
  //   return <></>;
  // }

  return pageType === 'provider' && domain === CaringDomains.LIVE ? (
    <>
      <Script
        id="talkfurther-provider-id"
        dangerouslySetInnerHTML={{
          __html: `window.furtherChatProviderProfile = ${
            providerId ?? 144989
          };`,
        }}
      />
      <Script
        id="talkfurther-init"
        src="https://js.talkfurther.com/talkfurther_init.min.js"
        strategy="lazyOnload"
      />
    </>
  ) : (
    <></>
  );
};

export default TalkFurther;
